<template>
  <b-modal id="modal-question" hide-footer header-class="align-items-center">
    <template #modal-header="{ close }">
      <h5 class="mb-0">
        {{ `${form.id ? "Editar" : "Agregar"} ${getLabelName}` }}
      </h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>
    <b-form class="mb-8">
      <b-form-group
        :label="`Nombre de ${getLabelName}`"
        label-for="questionName"
        class="mb-16"
      >
        <b-form-input
          v-model="form.name"
          id="questionName"
          :placeholder="`Nombre de ${getLabelName}`"
          :class="{ 'is-invalid': $v.form.name.$error }"
          type="text"
        ></b-form-input>
        <div v-if="$v.form.name.$error" class="invalid-feedback">
          <span v-if="!$v.form.name.required"
            >{{ getLabelName }} es Requerida.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        v-if="typeQuestion == TYPE_QUESTION"
        label="Tipo de Pregunta"
        label-for="questionType"
        class="mb-16"
      >
        <b-form-select
          v-model="form.question_type"
          :options="wrappedTypeQuestions"
          id="questionType"
          :class="{ 'is-invalid': $v.form.question_type.$error }"
          value-field="id"
          text-field="name"
        ></b-form-select>
        <div v-if="$v.form.question_type.$error" class="invalid-feedback">
          <span v-if="!$v.form.question_type.required"
            >Tipo de Pregunta es Requerido.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        label="Descripción"
        label-for="checklistDescription"
        class=""
      >
        <b-form-textarea
          v-model="form.description"
          id="checklistDescription"
          placeholder="Descripción"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
        v-if="
          typeQuestion === TYPE_QUESTION &&
          (questionTypeIsImage ||
            form.question_type == QUESTION_TYPES.image_text)
        "
        label="Cargar Imagen de Ejemplo"
        label-for="file-question"
        class="mb-16"
      >
        <input
          id="file-question"
          @change="setFile"
          type="file"
          placeholder="Seleccionar Imagen"
          accept=".jpg, .png, .jpeg"
        />
      </b-form-group>
      <b-form-group
        v-if="
          typeQuestion === TYPE_QUESTION &&
          ( form.question_type == QUESTION_TYPES.video_answer )
        "
        label="Cargar Video de Ejemplo"
        label-for="file-question"
        class="mb-16"
      >
        <input
          id="file-question"
          @change="setFile"
          type="file"
          placeholder="Seleccionar Video"
          accept=".mp4"
        />
      </b-form-group>
    </b-form>

    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
      <b-button
        type="submit"
        class="ml-16"
        variant="outline-primary"
        @click="handleSubmit"
      >
        <b-spinner v-if="loading" small type="grow" class="mr-8"></b-spinner>
        {{ form.id ? "Actualizar" : "Agregar" }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import {
  TYPE_QUESTION,
  TYPE_GROUP,
  QUESTION_TYPES,
} from "@/constants/question";

export default {
  mixins: [validationMixin],
  mounted() {
    this.getTypesQuestion();
  },
  data() {
    return {
      TYPE_GROUP,
      TYPE_QUESTION,
      QUESTION_TYPES,
      parentQuestion: null,
      typeQuestion: null,
      loading: false,
      form: {
        id: null,
        name: "",
        description: "",
        image: null,
        question_type: QUESTION_TYPES.gallery_image,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      description: {},
      question_type: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      getTypesQuestion: "question/getTypesQuestion",
      updateOrCreateQuestion: "question/updateOrCreateQuestion",
      uploadImageQuestion: "question/uploadImageQuestion",
    }),
    setQuestion({ id, name, typeId, description }) {
      this.form.id = id;
      this.form.name = name;
      this.form.description = description;
      this.form.question_type = typeId;
    },
    setFile(e) {
      if (e.target.files.length) {
        this.form.image = e.target.files[0];
      }
    },
    async handleSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (
        this.questionTypeIsImage &&
        this.typeQuestion === TYPE_QUESTION &&
        !this.form.image
      ) {
        return this.$bvToast.toast(`Debe Cargar una Imagen de Ejemplo`, {
          title: `Error de Validación`,
          variant: "danger",
          solid: false,
        });
      }
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const { id, ...rest } = this.form;
        let response = await this.updateOrCreateQuestion({
          ...rest,
          id,
          checklist_id: this.$route.params.id,
          parent: this.parentQuestion ? this.parentQuestion.id : "",
        });
        if (response.status == 201 || response.status == 200) {
          if (this.typeQuestion == TYPE_QUESTION) {
            await this.sendUploadImageQuestion(id ? { id } : response.data);
          } else {
            this.loading = false;
          }
          this.$bvToast.toast(
            `${this.getLabelName} ${
              id ? "Actualizada" : "Creada"
            } Correctamente`,
            {
              title: `Excelente`,
              variant: "primary",
              solid: false,
            }
          );
          this.closeModal();
          this.$emit("success");
        }
      }
    },
    async sendUploadImageQuestion(question) {
      await this.uploadImageQuestion({
        question_id: question.id,
        image: this.form.image,
      });
      this.loading = false;
    },
    openModal(parentQuestion, typeQuestion, question = null) {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.parentQuestion = parentQuestion;
      this.typeQuestion = typeQuestion;
      if (question) {
        this.setQuestion(question);
      }
      if (this.typeQuestion == TYPE_GROUP) {
        this.form.question_type = TYPE_GROUP;
      }
      this.$bvModal.show("modal-question");
    },
    closeModal() {
      this.$bvModal.hide("modal-question");
      this.form = {
        id: null,
        name: "",
        description: "",
        image: null,
        question_type: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  computed: {
    ...mapGetters({
      typesQuestion: "question/typesQuestion",
    }),
    getLabelName() {
      if (this.typeQuestion == TYPE_QUESTION) {
        return "Pregunta";
      }
      if (this.parentQuestion) {
        return "Subgrupo de Preguntas";
      }
      return "Grupo de Preguntas";
    },
    questionTypeIsImage() {
      return (
        this.form.question_type == QUESTION_TYPES.image ||
        this.form.question_type == QUESTION_TYPES.gallery_image
      );
    },
    wrappedTypeQuestions() {
      return [
        { id: null, name: "Selecciona un Tipo", disabled: true },
        ...this.typesQuestion.filter((item) => item.id !== TYPE_GROUP),
      ];
    },
  },
};
</script>

<style></style>
